import { graphql } from "@data/gql"

export const demanderLImportDUnDocumentQuery = graphql(`
  query demanderLImportDUnDocument(
    $identifiantDuPatient: String!
    $identifiantDuDocument: String!
    $identifiantDeLaCategorie: String!
    $nom: String!
    $extension: String!
  ) {
    demandeDImportDeDocument(
      identifiantDuPatient: $identifiantDuPatient
      identifiantDuDocument: $identifiantDuDocument
      identifiantDeLaCategorie: $identifiantDeLaCategorie
      nom: $nom
      extension: $extension
    ) {
      adresse
    }
  }
`)
