import { SVGProps } from "react"

export const ArrowRight = (props: SVGProps<SVGSVGElement>) => (
  <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3.53599 1.17941L7.85742 5.50084L3.53599 9.82227"
      stroke="#101010"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
