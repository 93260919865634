export const cropAround = (text: string, word?: string) => {
  if (!word) return text
  const index = text.indexOf(word)
  if (index === -1) {
    return text
  }
  const start = Math.max(0, index - 10)
  const end = Math.min(text.length, index + word.length + 10)
  const croppedText = text.substring(start, end)
  const shouldCropStart = start > 0
  const shouldCropEnd = end < text.length && !text[end - 1].match(/[.!?]/)
  return `${shouldCropStart ? "..." : ""}${croppedText}${shouldCropEnd ? "..." : ""}`
}
