import { classNames } from "@ds/classNames"
import { ChevronDown } from "@ds/icons/ChevronDown"
import { ChevronUp } from "@ds/icons/ChevronUp"
import { Filter } from "@ds/icons/Filter"
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/react"
import { DropDownCheckedList } from "./DropDownCheckedList"
import { useTranslation } from "react-i18next"
import { ToolButton } from "@ds/button/ToolButton"
import { useListeSoignantsDuCabinet } from "./hooks/useListeSoignantsDuCabinet"
import { usePreferences } from "@infra/preferences/usePreferences"
import { Soignant } from "./domain/Soignant"
import { MagnifyingGlassEmpty } from "@ds/icons/MagnifyingGlassEmpty"
import { useEffect, useRef, useState } from "react"
import { SearchBar } from "@features/patient/pages/SearchBar"

export type DropDownFiltreMessagerie = { id: string; nom: string }

type FiltreMessagerieProps = {
  filtres: DropDownFiltreMessagerie[]
  selection: DropDownFiltreMessagerie
  setSelection: (value: DropDownFiltreMessagerie) => void
  count: number | null
  onRecherche: (recherche: string) => void
}

export const FiltreMessagerie = ({ selection, setSelection, filtres, count, onRecherche }: FiltreMessagerieProps) => {
  const rechercheActive = import.meta.env.VITE_RECHERCHE_STREAM === "true"
  const { t } = useTranslation()
  const { listeSoignantsDuCabinet } = useListeSoignantsDuCabinet()
  const { filtreParticipantsMessagerie, setPreferences } = usePreferences()
  const [showRecherche, setShowRecherche] = useState(false)
  const recherche = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    recherche.current?.focus()
  }, [])

  return (
    <div className="flex max-h-8 items-center justify-stretch gap-2 pl-2">
      {showRecherche && rechercheActive && (
        <div data-testid="barre-recherche-conversations" className="rounded-lg shadow-light-shadow">
          <SearchBar
            onClose={() => {
              setShowRecherche(false)
              onRecherche("")
            }}
            onType={onRecherche}
          />
        </div>
      )}
      {!showRecherche && (
        <>
          <div data-testid="filtre-inbox" className="z-10 flex-grow">
            <Listbox value={selection} onChange={setSelection}>
              {({ open }) => (
                <>
                  <ListboxButton className="group w-full">
                    <div className="flex w-full items-center justify-between gap-2 rounded-md border border-transparent bg-extra-light-grey px-3 py-2 text-h6 group-hover:border-black group-hover:bg-white group-hover:py-[6px]">
                      <div data-testid={"selected-placeholder"} className="truncate text-left text-h6">
                        <div className="flex items-center gap-1">
                          <div>{selection.nom}</div>{" "}
                          <div className="text-p-tiny text-grey">{count === null ? "" : count}</div>
                        </div>
                      </div>
                      <div className="text-[16px]">{open ? <ChevronUp /> : <ChevronDown />}</div>
                    </div>
                  </ListboxButton>
                  <div className="relative">
                    <ListboxOptions className="absolute top-1 max-h-64 w-full gap-1 overflow-auto rounded-xl bg-white p-3 shadow-light-shadow">
                      {filtres.map((item) => (
                        <ListboxOption
                          key={item.id}
                          data-testid={"filtre-option-" + item.id}
                          value={item}
                          className="flex cursor-pointer items-center gap-2 rounded-lg px-3 py-2 text-p-tiny ui-selected:bg-extra-light-grey ui-active:bg-extra-light-grey ui-not-active:bg-inherit"
                        >
                          <div className="truncate ui-selected:text-dark-plum">{item.nom}</div>
                        </ListboxOption>
                      ))}
                    </ListboxOptions>
                  </div>
                </>
              )}
            </Listbox>
          </div>
          {rechercheActive && (
            <ToolButton
              title={t("messagerie.rechercher")}
              icon={(props) => <MagnifyingGlassEmpty {...props} className="text-black hover:text-grey" />}
              type="outlined"
              className="h-8 w-8"
              onClick={() => setShowRecherche(!showRecherche)}
              dataTestId="filtre-recherche-conversations"
            />
          )}
        </>
      )}

      <DropDownCheckedList
        titre={t("messagerie.filtrerParParticipants")}
        bouton={
          <ListboxButton className="group" data-testid="filtre-participants">
            <ToolButton
              title={t("messagerie.ajouterParticipant")}
              icon={(props) => (
                <Filter
                  {...props}
                  className={classNames(
                    "text-h5",
                    filtreParticipantsMessagerie?.length > 0 ? "text-black" : "text-transparent",
                  )}
                />
              )}
              dot={filtreParticipantsMessagerie?.length > 0 ? filtreParticipantsMessagerie?.length : undefined}
              type="outlined"
              className="h-8 w-8"
            />
          </ListboxButton>
        }
        liste={listeSoignantsDuCabinet ?? []}
        selection={
          listeSoignantsDuCabinet?.filter((soignant) => filtreParticipantsMessagerie.includes(soignant.id)) ?? []
        }
        setSelection={(soignants: Soignant[]) =>
          setPreferences({ filtreParticipantsMessagerie: soignants.map((s) => s.id) })
        }
        boutonDeselectionner={
          <div
            className="flex flex-grow cursor-pointer items-center justify-center rounded-lg border border-transparent p-2 text-p-tiny text-grey underline hover:border-extra-light-grey"
            onClick={() => setPreferences({ filtreParticipantsMessagerie: [] })}
            data-testid="deselectionner-tout"
          >
            {t("messagerie.toutDeselectionner")}
          </div>
        }
      />
    </div>
  )
}
