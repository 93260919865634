import { useGraphQLQuery } from "@data/useGraphQL"
import { useTranslation } from "react-i18next"
import { cabinetsQuery } from "./graphql/cabinetsQuery"
import { useEffect } from "react"
import { usePreferences } from "@infra/preferences/usePreferences"
import { useNavigate } from "@tanstack/react-router"
import { appRoute } from "@infra/navigation/router"

export const ChargementDesCabinets = () => {
  const { t } = useTranslation()

  const { data } = useGraphQLQuery({ document: cabinetsQuery })
  const { identifiantDuCabinet, setPreferences } = usePreferences()
  const navigate = useNavigate()

  useEffect(() => {
    if (data?.data?.cabinets.length === 0 || !data?.data) {
      return
    }

    const identifiantDuPremierCabinet = data?.data?.cabinets[0].id
    if (identifiantDuCabinet != identifiantDuPremierCabinet) {
      setPreferences({ identifiantDuCabinet: identifiantDuPremierCabinet })
    }

    navigate({ to: appRoute.to })
  }, [data, setPreferences, identifiantDuCabinet, navigate])

  if (data?.data?.cabinets.length === 0) {
    return <div data-testid="chargement">{t("accesAAucunCabinet")}</div>
  }

  return <div data-testid="chargement">{t("chargement")}</div>
}
