import { classNames } from "@ds/classNames"
import { Warning } from "@ds/icons/Warning"
import { AnimatePresence, motion } from "framer-motion"
import { ReactElement } from "react"

export const ErrorMessage = ({
  children,
  className,
  display,
}: {
  children: ReactElement | string | null
  className?: string
  display: boolean
}) => {
  return (
    <AnimatePresence>
      {display ? (
        <motion.div
          initial={{ height: 0, opacity: 0, marginTop: 0 }}
          exit={{ height: 0, opacity: 0, marginTop: 0 }}
          animate={{
            height: "auto",
            opacity: 1,
            marginTop: 8,
          }}
          className={classNames("flex gap-2 text-h5 text-error", className)}
        >
          <Warning className="h-5 w-5" />
          <div className="flex-1 text-left">{children}</div>
        </motion.div>
      ) : null}
    </AnimatePresence>
  )
}
