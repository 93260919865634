import { ReactNode, useNavigate, useRouterState } from "@tanstack/react-router"
import { MagnifyingGlass } from "../icons/MagnifyingGlass"
import { Clock } from "../icons/Clock"
import { Envelope } from "../icons/Envelope"
import { Logo } from "../logo/SizableLogo"
import { useTranslation } from "react-i18next"
import { di } from "@di"
import { ArrowsLeft } from "@ds/icons/ArrowsLeft"
import { ArrowsRight } from "@ds/icons/ArrowsRight"
import { classNames } from "@ds/classNames"
import { House } from "@ds/icons/House"
import { usePreferences } from "@infra/preferences/usePreferences"
import { useRef } from "react"
import { appRoute, messagerieRoute, preconsultationRoute } from "@infra/navigation/router"
import { UpdateAvailableCard } from "@infra/updates/components/UpdateAvailableCard"
import { Tag } from "@ds/tag/Tag"
import { useFlags } from "launchdarkly-react-client-sdk"
import { rootRoute } from "@infra/navigation/rootRoute"

export const SideBar = () => {
  const { sideBarOuverte, setPreferences } = usePreferences()
  const { t } = useTranslation()
  const navigate = useNavigate({ from: rootRoute.fullPath })
  const router = useRouterState()
  const { messagerieStream } = useFlags()

  const toggleSideBar = () => setPreferences({ sideBarOuverte: !sideBarOuverte })

  const modaleOuverte = router.location.searchStr.includes("modal=true")
  return (
    <div
      data-testid="side-bar"
      className={classNames(
        "sticky top-0 flex h-screen flex-col bg-extra-light-grey",
        sideBarOuverte ? "w-60" : "w-16",
      )}
    >
      <div>
        {sideBarOuverte ? (
          <>
            <div className="mx-6 mt-7 flex items-center justify-between">
              <Logo className="text-2xl" />
              <ArrowsLeft
                data-testid={"arrows-left"}
                className="cursor-pointer text-xl text-grey hover:text-light-grey"
                onClick={toggleSideBar}
              />
            </div>
            <div className="mx-4 mt-8 text-p-small">
              <di.SelecteurDeCabinet />
            </div>
          </>
        ) : (
          <>
            <div className="mx-4 mt-7 flex h-6 items-center">
              <ArrowsRight
                data-testid={"arrows-right"}
                className="cursor-pointer text-xl text-grey hover:text-light-grey"
                onClick={toggleSideBar}
              />
            </div>
            <div className="mr-4 mt-8 flex flex-col">
              <SideBarButton icon={<House />} showText={sideBarOuverte} onClick={toggleSideBar} />
            </div>
          </>
        )}

        <div className={classNames("mt-9 flex flex-col gap-1", sideBarOuverte ? "mr-6" : "mr-4")}>
          <SideBarButton
            text={t("rechercher")}
            icon={<MagnifyingGlass />}
            showText={sideBarOuverte}
            isSelected={modaleOuverte}
            onClick={() => {
              navigate({ search: (prev: Record<string, string>) => ({ ...prev, modal: true }) })
            }}
          />
          <SideBarButton
            text={t("consultations")}
            icon={<Clock />}
            isSelected={
              (router.location.pathname === appRoute.to ||
                router.matches.findIndex((element) => element.routeId === preconsultationRoute.id) !== -1) &&
              !modaleOuverte
            }
            onClick={() => navigate({ to: appRoute.to })}
            showText={sideBarOuverte}
          />
          <SideBarButton
            text={t("messagerie.messagerie")}
            icon={<Envelope />}
            isSelected={
              router.matches.findIndex((element) => element.routeId === messagerieRoute.id) !== -1 && !modaleOuverte
            }
            showText={sideBarOuverte}
            disabled={!messagerieStream}
            tag={!messagerieStream && <Tag content={t("aVenir")} />}
            onClick={() => navigate({ to: "/messagerie" })}
            alert={<di.UnreadCount />}
          />
        </div>
      </div>
      <div className="flex-1" />
      <UpdateAvailableCard className="mx-6 mb-6" />
      <di.UserProfile />
    </div>
  )
}

type SideBarButtonProps = {
  text?: string
  icon: ReactNode
  isSelected?: boolean
  onClick?: () => void
  showText: boolean
  disabled?: boolean
  tag?: ReactNode
  alert?: ReactNode
}
const SideBarButton = ({
  text,
  icon,
  isSelected = false,
  onClick,
  showText,
  disabled = false,
  tag,
  alert,
}: SideBarButtonProps) => {
  const btnRef = useRef<HTMLButtonElement | null>(null)

  return (
    <>
      <button
        data-testid={"sidebutton-" + text}
        ref={btnRef}
        className={classNames(
          "group h-10 rounded-r-xl pr-2",
          showText ? "px-6" : "pl-4 pr-2",
          isSelected ? "bg-light-grey text-dark-plum" : "text-grey",
          disabled ? "" : "hover:bg-light-grey hover:text-dark-plum",
        )}
        onClick={onClick}
        disabled={disabled}
      >
        <div className="flex items-center gap-2 text-p-small">
          {/*TODO: alignement icone texte*/}
          <div
            className={classNames(
              "stroke-black text-xl",
              isSelected ? "stroke-white text-dark-plum" : "text-light-grey",
              disabled ? "" : "group-hover:stroke-white group-hover:text-dark-plum",
            )}
          >
            {icon}
          </div>
          {showText && text}
          {showText && tag}
          {alert && !showText && <div className="relative">{alert}</div>}
          {alert && showText && <div className="flex flex-1 justify-end">{alert}</div>}
        </div>
      </button>
    </>
  )
}
