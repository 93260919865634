import { format, formatDistanceToNow, isToday, isTomorrow, isYesterday } from "date-fns"
import { fr } from "date-fns/locale"
import frLocaleJson from "@locale/fr.json"

export const dateHeureRelative = (date: Date) => {
  const now = new Date()

  if (isToday(date)) {
    return frLocaleJson["dateHeure"].today + format(date, "HH:mm")
  } else if (isTomorrow(date)) {
    return frLocaleJson["dateHeure"].tomorrow + format(date, "HH:mm")
  } else if (isYesterday(date)) {
    return frLocaleJson["dateHeure"].yesterday + format(date, "HH:mm")
  }
  if (date > now) {
    const daysDifference = Math.ceil((date.getTime() - now.getTime()) / (1000 * 60 * 60 * 24))
    if (daysDifference <= 7) {
      return format(date, "EEEE", { locale: fr }) + frLocaleJson["dateHeure"].nextWeek + format(date, "HH:mm")
    }
  } else {
    const daysDifference = Math.ceil((now.getTime() - date.getTime()) / (1000 * 60 * 60 * 24))
    if (daysDifference <= 7) {
      return format(date, "EEEE", { locale: fr }) + frLocaleJson["dateHeure"].lastWeek + format(date, "HH:mm")
    }
  }
  return formatDistanceToNow(date, { addSuffix: true, includeSeconds: false, locale: fr })
}

export const dateRelative = (date: Date) => {
  const now = new Date()

  if (isToday(date)) {
    return frLocaleJson["date"].today
  } else if (isTomorrow(date)) {
    return frLocaleJson["date"].tomorrow
  } else if (isYesterday(date)) {
    return frLocaleJson["date"].yesterday
  }
  if (date > now) {
    const daysDifference = Math.ceil((date.getTime() - now.getTime()) / (1000 * 60 * 60 * 24))
    if (daysDifference <= 7) {
      return format(date, "EEEE", { locale: fr }) + frLocaleJson["date"].nextWeek
    }
  } else {
    const daysDifference = Math.ceil((now.getTime() - date.getTime()) / (1000 * 60 * 60 * 24))
    if (daysDifference <= 7) {
      return format(date, "EEEE", { locale: fr }) + frLocaleJson["date"].lastWeek
    }
  }
  return formatDistanceToNow(date, { addSuffix: true, includeSeconds: false, locale: fr })
}
