import { Shimmer } from "@ds/animation/Shimmer"
import { classNames } from "@ds/classNames"
import { Ai } from "@ds/icons/Ai"
import { Undo } from "@ds/icons/Undo"
import { useReducer } from "react"
import { useTranslation } from "react-i18next"

interface BoutonMettreEnFormeProps {
  contenu: string
  setContenu: (contenu: string) => void
  mettreEnForme: () => Promise<string>
  miseEnFormeEnCours: boolean
}

interface State {
  contenuPrecedent: string
  contenuAvantMiseEnForme: string | null
  contenuMisEnForme: string | null
  peutAnnuler: boolean
  peutRetablir: boolean
}

interface Action {
  type: "METTRE_EN_FORME" | "ANNULER" | "RETABLIR" | "MISE_EN_FORME_REUSSIE"
  contenu: string
}

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "METTRE_EN_FORME":
      return {
        contenuPrecedent: action.contenu,
        contenuMisEnForme: state.contenuMisEnForme,
        contenuAvantMiseEnForme: action.contenu,
        peutAnnuler: false,
        peutRetablir: false,
      }
    case "ANNULER":
      return {
        ...state,
        contenuPrecedent: action.contenu,
        peutAnnuler: false,
        peutRetablir: true,
      }
    case "RETABLIR":
      return {
        ...state,
        contenuPrecedent: action.contenu,
        peutAnnuler: true,
        peutRetablir: false,
      }
    case "MISE_EN_FORME_REUSSIE":
      return {
        ...state,
        peutAnnuler: true,
        contenuMisEnForme: action.contenu,
      }
  }
}

export const BoutonMettreEnForme = ({
  contenu,
  setContenu,
  mettreEnForme,
  miseEnFormeEnCours,
}: BoutonMettreEnFormeProps) => {
  const contenuEstVide = contenu.replace(/[\s\n#]/g, "").trim().length === 0
  const [{ peutAnnuler, peutRetablir, contenuPrecedent, contenuMisEnForme, contenuAvantMiseEnForme }, dispatch] =
    useReducer(reducer, {
      contenuPrecedent: "",
      contenuMisEnForme: null,
      contenuAvantMiseEnForme: null,
      peutAnnuler: false,
      peutRetablir: false,
    })

  const contenuDifferentDuContenuMisEnForme = contenu.trim() !== contenuMisEnForme?.trim()
  const contenuDifferentDuContenuPrecedent = contenu.trim() !== contenuAvantMiseEnForme?.trim()

  return (
    <button
      data-testid="mettre-en-forme-button"
      className={classNames(
        "relative mr-5 flex items-center gap-2 rounded p-2 text-h4 shadow-drop-shadow",
        !miseEnFormeEnCours && "disabled:bg-white disabled:text-grey disabled:opacity-100 disabled:shadow-none",
        !miseEnFormeEnCours && "text-indigo-900 hover:opacity-60 active:opacity-100",
        miseEnFormeEnCours && "bg-indigo-900 text-white",
      )}
      disabled={miseEnFormeEnCours || contenuEstVide}
      onClick={() => {
        if (peutRetablir && !contenuDifferentDuContenuPrecedent) {
          setContenu(contenuPrecedent)
          dispatch({ type: "RETABLIR", contenu: contenu })
        } else if (peutAnnuler && !contenuDifferentDuContenuMisEnForme) {
          setContenu(contenuPrecedent)
          dispatch({ type: "ANNULER", contenu: contenu })
        } else {
          dispatch({ type: "METTRE_EN_FORME", contenu: contenu })
          mettreEnForme()
            .then((contenuMisEnForme) => {
              dispatch({ type: "MISE_EN_FORME_REUSSIE", contenu: contenuMisEnForme })
            })
            .catch(() => {})
        }
      }}
    >
      <ButtonContent
        peutAnnuler={peutAnnuler}
        peutRetablir={peutRetablir}
        miseEnFormeEnCours={miseEnFormeEnCours}
        contenuDifferentDuContenuMisEnForme={contenuDifferentDuContenuMisEnForme}
        contenuDifferentDuContenuPrecedent={contenuDifferentDuContenuPrecedent}
      />
      {miseEnFormeEnCours && <Shimmer show className="absolute bottom-0 left-0 right-0 top-0" />}
    </button>
  )
}

const ButtonContent = ({
  peutAnnuler,
  peutRetablir,
  miseEnFormeEnCours,
  contenuDifferentDuContenuMisEnForme,
  contenuDifferentDuContenuPrecedent,
}: {
  peutAnnuler: boolean
  peutRetablir: boolean
  miseEnFormeEnCours: boolean
  contenuDifferentDuContenuMisEnForme: boolean
  contenuDifferentDuContenuPrecedent: boolean
}) => {
  const { t } = useTranslation()
  switch (true) {
    case miseEnFormeEnCours:
      return (
        <>
          <Ai />
          {t("miseEnForme")}
        </>
      )
    case peutRetablir && !contenuDifferentDuContenuPrecedent:
      return (
        <>
          <Undo className="-scale-x-100" />
          {t("retablir")}
        </>
      )
    case peutAnnuler && !contenuDifferentDuContenuMisEnForme:
      return (
        <>
          <Undo />
          {t("annuler")}
        </>
      )
    default:
      return (
        <>
          <Ai />
          {t("mettreEnForme")}
        </>
      )
  }
}
