import { SVGProps } from "react"

export const Document = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 23 31" {...props}>
    <rect y="0.234375" width="22.1" height="30.5294" rx="1.04762" fill="#EFC712" />
    <path
      d="M3.08329 5.89984C3.63422 5.94493 4.16027 5.89533 4.72353 5.95302C5.28715 6.01075 5.83187 5.86817 6.39038 5.8399C7.60543 5.77839 8.80609 5.95121 10.0218 5.85145C10.8492 5.78356 11.7034 5.82819 12.5309 5.89157C13.1602 5.93976 13.8414 5.80243 14.4724 5.77468C15.1405 5.7453 15.8094 5.82871 16.4754 5.83631C16.9993 5.84229 17.6807 5.91025 18.1484 5.66322"
      stroke="#3A1C33"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M3.21561 11.652C3.76681 11.6938 4.29255 11.6411 4.85614 11.6955C5.4201 11.7498 5.96396 11.604 6.52229 11.5724C7.73695 11.5037 8.93862 11.6694 10.1537 11.5624"
      stroke="#3A1C33"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
)
