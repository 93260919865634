import { useLocalStorage } from "usehooks-ts"

export interface Preferences {
  identifiantDuCabinet: string | null
  sideBarOuverte: boolean
  filtreMedecin?: { [key: string]: { id: string; name: string }[] }
  filtreInboxMessagerie: string | null
  filtreParticipantsMessagerie: string[]
  messagesATraiterUniquement: boolean
}

const initialPreferences = {
  identifiantDuCabinet: null,
  sideBarOuverte: true,
  filtreInboxMessagerie: "ma-boite",
  filtreParticipantsMessagerie: [],
  messagesATraiterUniquement: false,
}

export const usePreferences = () => {
  const [preferences, setLocalStoragePreferences] = useLocalStorage<Preferences>("preferences", initialPreferences)

  if (!preferences.filtreInboxMessagerie) {
    preferences.filtreInboxMessagerie = "ma-boite"
  }
  if (!preferences.filtreParticipantsMessagerie) {
    preferences.filtreParticipantsMessagerie = []
  }

  const setPreferences = (nouvellesPreferences: Partial<Preferences>) => {
    setLocalStoragePreferences({ ...preferences, ...nouvellesPreferences })
  }

  return {
    setPreferences,
    ...preferences,
  }
}
