import { ReactNode } from "react"
import { SideBar } from "./SideBar"
import { classNames } from "@ds/classNames"

type PageLayoutProps = {
  className?: string | undefined
  children: ReactNode
}

export const PageLayout = ({ children, className }: PageLayoutProps) => {
  return (
    <div className="flex">
      <SideBar />
      <div className={classNames("flex-1 overflow-auto", className)}>{children}</div>
    </div>
  )
}

type PageTitleLayoutProps = {
  className?: string | undefined
  title: string
  children: ReactNode
  margin?: string
  actions?: ReactNode
}

export const PageTitleLayout = ({ children, title, margin, className, actions }: PageTitleLayoutProps) => (
  <PageLayout className={className}>
    <div className="flex items-center">
      <div data-testid="page-title" className={classNames("mb-6 text-h1", margin ? margin : "ml-20 mt-20")}>
        {title}
      </div>
      <div className="flex-grow" />
      <div className="mr-8">{actions}</div>
    </div>
    {children}
  </PageLayout>
)
