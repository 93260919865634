import { ReactNode } from "react"
import parse from "html-react-parser"

export const highlight = (input: string, highlight?: string): ReactNode => {
  if (!highlight || !input?.toLowerCase().includes(highlight?.toLowerCase())) return input
  return parse(highlightWord(input, highlight))
}

const highlightWord = (input: string, word: string): string => {
  const regex = new RegExp(`(${word})`, "gi")
  return input.replace(regex, '<mark className="bg-yellow-hover rounded">$1</mark>')
}
