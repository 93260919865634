import { SVGProps } from "react"

export const Ai = (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => (
  <svg data-testid={"ai"} width="1em" height="1em" viewBox="0 0 18 18" fill="none" {...props}>
    <g>
      <path
        d="M8.69959 6.661L8.9375 5.375L9.17541 6.661C9.61481 9.03617 11.3322 10.8928 13.5292 11.3678L14.7188 11.625L13.5292 11.8822C11.3322 12.3572 9.61481 14.2138 9.17541 16.589L8.9375 17.875L8.69959 16.589C8.26019 14.2138 6.54284 12.3572 4.3458 11.8822L3.15625 11.625L4.3458 11.3678C6.54284 10.8928 8.26019 9.03617 8.69959 6.661Z"
        fill="currentColor"
      />
      <path
        d="M3.70403 0.809992L3.82812 0.166992L3.95222 0.809992C4.18144 1.99757 5.07722 2.92587 6.22325 3.16339L6.84375 3.29199L6.22325 3.42059C5.07722 3.65809 4.18144 4.58639 3.95222 5.77399L3.82812 6.41699L3.70403 5.77399C3.47481 4.58639 2.579 3.65809 1.433 3.42059L0.8125 3.29199L1.433 3.16339C2.579 2.92587 3.47481 1.99757 3.70403 0.809992Z"
        fill="currentColor"
      />
      <path
        d="M15.7107 1.63668L15.7969 1.20801L15.883 1.63668C16.0422 2.42841 16.6641 3.04726 17.4598 3.20561L17.8906 3.29136L17.4598 3.37708C16.6641 3.53543 16.0422 4.15428 15.883 4.94601L15.7969 5.37468L15.7107 4.94601C15.5516 4.15428 14.9296 3.53543 14.1339 3.37708L13.7031 3.29136L14.1339 3.20561C14.9296 3.04726 15.5516 2.42841 15.7107 1.63668Z"
        fill="currentColor"
      />
    </g>
  </svg>
)
