import { buildNomComplet, Soignant } from "@features/messagerie/domain/Soignant"
import { Participant } from "@features/messagerie/Participant"
import { Listbox, ListboxOption, ListboxOptions } from "@headlessui/react"
import { ReactNode } from "react"

type DropDownCheckedListProps = {
  titre?: string
  bouton: ReactNode
  liste: Soignant[]
  selection: Soignant[]
  setSelection: (value: Soignant[]) => void
  boutonDeselectionner?: ReactNode
}

export const DropDownCheckedList = ({
  titre,
  bouton,
  liste,
  selection,
  setSelection,
  boutonDeselectionner,
}: DropDownCheckedListProps) => {
  const byFirstName = (a: Soignant, b: Soignant) => {
    if (a.prenom && b.prenom) {
      return a.prenom.localeCompare(b.prenom)
    }
    return 0
  }

  return (
    <div data-testid={"dropdown-" + titre?.toLowerCase()} className="flex">
      <Listbox value={selection} onChange={setSelection} multiple>
        {bouton}
        <div className="relative z-10">
          <ListboxOptions
            className="w-54 mt-1 max-h-64 gap-1 overflow-auto rounded-xl bg-white p-3 shadow-light-shadow"
            anchor="bottom end"
          >
            {titre && <div className="mb-1 text-h7">{titre.toUpperCase()}</div>}
            {liste.sort(byFirstName).map((item) => (
              <ListboxOption
                key={item.id}
                data-testid={"soignant-option-" + item.id}
                value={item}
                className="flex cursor-pointer items-center gap-2 rounded-lg px-3 py-2 text-p-tiny ui-selected:bg-extra-light-grey ui-active:bg-extra-light-grey ui-not-active:bg-inherit"
              >
                {({ selected }) => (
                  <>
                    <input
                      type="checkbox"
                      checked={selected}
                      className="outline-1 ui-selected:accent-dark-plum"
                      onChange={() => {}} // This is needed to prevent a warning
                    />
                    <div className="w-5">
                      <Participant prenom={item.prenom} nom={item.nom} urlAvatar={item.urlAvatar} id={item.id} />
                    </div>
                    <div className="truncate text-dark-plum">{buildNomComplet(item)}</div>
                  </>
                )}
              </ListboxOption>
            ))}
            {boutonDeselectionner && boutonDeselectionner}
          </ListboxOptions>
        </div>
      </Listbox>
    </div>
  )
}
