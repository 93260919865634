import { documentsQuery } from "@data/patient/documentsQuery"
import { useGraphQLQuery } from "@data/useGraphQL"
import { Cross } from "@ds/icons/Cross"
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react"
import { useTranslation } from "react-i18next"
import { startOfMonth, format } from "date-fns"
import { fr } from "date-fns/locale"
import { capitalize } from "@utils/capitalize"
import { Fragment } from "react/jsx-runtime"
import { Document } from "@ds/icons/Document"
import { useState } from "react"
import { DocumentsQueryQuery } from "@data/gql/graphql"
import { classNames } from "@ds/classNames"
import { SubmitButton } from "@ds/button/SubmitButton"
import { PrevisualisationDocument } from "@features/messagerie/PrevisualisationDocument"

type Document = DocumentsQueryQuery["documents"][number]

interface ChoisirUnDocumentDialogProps {
  onClose: () => unknown
  identifiantDuProfilPatient: string
  onDocumentJoint: (document: Document) => unknown
}

export const ChoisirUnDocumentDialog = ({
  onClose,
  identifiantDuProfilPatient,
  onDocumentJoint,
}: ChoisirUnDocumentDialogProps) => {
  const { t } = useTranslation()
  const { data } = useGraphQLQuery({
    document: documentsQuery,
    variables: { identifiantDuProfilPatient: identifiantDuProfilPatient },
  })

  const [selectedDocument, setSelectedDocument] = useState<Document | null>(null)
  return (
    <Dialog open onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 flex w-screen animate-fadein flex-row-reverse bg-secondary-black">
        <DialogPanel className="flex h-full flex-[0_1_800px] animate-slide-from-right flex-col bg-white px-6 pt-6">
          <DialogTitle className="flex items-center justify-between rounded-t-xl bg-white text-h4">
            <div className="flex-1 text-h3">
              {selectedDocument
                ? t("messagerie.joindreXXX", { nom: selectedDocument.nom })
                : t("messagerie.choisirUnDocument")}
            </div>
            <button data-testid="modal-close" onClick={onClose}>
              <Cross className="text-p-medium text-black" />
            </button>
          </DialogTitle>
          {selectedDocument ? (
            <DocumentPreview
              className="flex-1 overflow-hidden"
              document={selectedDocument}
              cancel={() => setSelectedDocument(null)}
              onDocumentJoint={(document) => {
                onDocumentJoint(document)
                onClose()
              }}
            />
          ) : (
            <DocumentsList
              documents={data?.data?.documents}
              onClick={(document) => {
                setSelectedDocument(document)
              }}
            />
          )}
        </DialogPanel>
      </div>
    </Dialog>
  )
}

const DocumentPreview = ({
  document,
  className,
  cancel,
  onDocumentJoint,
}: {
  document: Document
  className: string
  cancel: () => unknown
  onDocumentJoint: (document: Document) => unknown
}) => {
  const { t } = useTranslation()
  return (
    <div className={classNames("flex flex-col items-stretch gap-4 py-6", className)}>
      <div className="flex flex-1 overflow-auto">
        <PrevisualisationDocument
          identifiantDuDocument={document.id}
          nom={document.nom}
          className="flex-1 p-2"
          allPages
        />
      </div>
      <div className="flex flex-row justify-end gap-4">
        <SubmitButton type="outlined" title={t("annuler")} onClick={cancel} />
        <SubmitButton
          type="tonal"
          title={t("messagerie.joindreLeDocument")}
          onClick={() => onDocumentJoint(document)}
        />
      </div>
    </div>
  )
}

const DocumentsList = ({
  documents,
  onClick,
}: {
  documents: Document[] | null | undefined
  onClick: (document: Document) => unknown
}) => (
  <div className="grid grid-cols-3 overflow-auto">
    {documents &&
      Object.entries(
        documents.reduce(
          (acc, document) => {
            const startOfTheMonth = startOfMonth(document.dateDuDocument)
            if (!acc[startOfTheMonth.toISOString()]) {
              const emptyList: typeof documents = []
              acc[startOfTheMonth.toISOString()] = emptyList
            }
            acc[startOfTheMonth.toISOString()]?.push(document)
            return acc
          },
          {} as Record<string, typeof documents>,
        ),
      )
        .sort(([a], [b]) => (a > b ? -1 : 1))
        .map(([monthYear, documents]) => (
          <Fragment key={monthYear}>
            <div className="col-span-3 pt-6">
              <div className="text-h4">{capitalize(format(monthYear, "MMMM yyyy", { locale: fr }))}</div>
            </div>
            {documents
              .sort((a, b) => new Date(b.dateDuDocument).getTime() - new Date(a.dateDuDocument).getTime())
              .map((document) => (
                <div
                  key={document.id}
                  className="mt-2 flex cursor-pointer flex-col gap-1 rounded-xl p-2 hover:bg-extra-light-grey"
                  onClick={() => {
                    onClick(document)
                  }}
                >
                  <div className="text-h7">{document.categoriePrincipale.nom}</div>
                  <div className="flex flex-row items-center gap-2">
                    <Document className="text-2xl" />
                    <div className="flex flex-1 flex-col">
                      <div className="text-h5">{capitalize(document.nom)}</div>
                      <div className="text-p-tiny">{format(document.dateDuDocument, "d MMMM", { locale: fr })}</div>
                    </div>
                  </div>
                </div>
              ))}
          </Fragment>
        ))}
  </div>
)
