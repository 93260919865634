import { ReactNode } from "@tanstack/react-router"
import { Channel, DefaultGenerics, StreamChat } from "stream-chat"
import { MAX_QUERY_CHANNELS_LIMIT } from "stream-chat-react"
import { Channel as ChannelType } from "stream-chat/dist/types/channel"

export const channelRenderFilterFn = (
  channelList: Channel[],
  filtreInboxMessagerie: string,
  filtreParticipantsMessagerie: string[],
  client: StreamChat,
  messagesATraiterUniquement: boolean,
) => {
  let channels: ChannelType[] = []
  const conversationNonArchivee = (channel: ChannelType) =>
    channel.data?.archive_le === undefined && (messagesATraiterUniquement ? channel.data?.demandeAideMedecin : true)

  if (filtreInboxMessagerie === "tous") {
    channels = channelList.filter(conversationNonArchivee)
  }

  if (filtreInboxMessagerie === "archive") {
    const conversationArchivee = (channel: ChannelType) => channel.data?.archive_le !== undefined
    channels = channelList.filter(conversationArchivee)
  }

  if (filtreInboxMessagerie === "ma-boite") {
    const utilisateurDansLaConversation = (channel: ChannelType) =>
      client.user?.id && channel.state.members[client.user?.id] !== undefined

    // ma boite
    const filtreMaBoiteAssistanteMedicale = (conversation: ChannelType<DefaultGenerics>) => {
      const pasDAssistanteMedicaleDansLaConversation = !Object.values(conversation.state.members).some(
        (member) => member.user?.metier === "assistante_medicale",
      )

      return (
        (pasDAssistanteMedicaleDansLaConversation || utilisateurDansLaConversation(conversation)) &&
        (messagesATraiterUniquement ? conversation.data?.demandeAideMedecin : true) &&
        conversationNonArchivee(conversation)
      )
    }

    const filtreMaBoiteMedecin = (conversation: ChannelType) =>
      utilisateurDansLaConversation(conversation) &&
      conversationNonArchivee(conversation) &&
      (messagesATraiterUniquement ? conversation.data?.demandeAideMedecin : true)

    const medecin = client.user?.metier === "medecin"

    channels = channelList.filter(medecin ? filtreMaBoiteMedecin : filtreMaBoiteAssistanteMedicale)
  }

  //filtre par participants
  return channels.filter((channel) =>
    filtreParticipantsMessagerie?.length > 0
      ? Object.values(channel.state.members).some((member) => filtreParticipantsMessagerie.includes(member.user_id!))
      : true,
  )
}

export type ConversationTrouvee = {
  id: string
  messageTrouve?: ReactNode
}

export const channelRenderFilterSearchFn = async (
  recherche: string,
  identifiantDuCabinet: string,
  filtreParticipantsMessagerie: string[],
  client: StreamChat,
  messagesATraiterUniquement: boolean,
): Promise<ConversationTrouvee[]> => {
  const options = {
    limit: MAX_QUERY_CHANNELS_LIMIT,
    message_limit: 300,
  }

  const channels = await client?.queryChannels(
    {
      $and: [
        { type: "messaging" },
        { team: identifiantDuCabinet },
        ...(messagesATraiterUniquement ? [{ demandeAideMedecin: true }] : []),
        {
          $or: [
            { objet: { $autocomplete: recherche } },
            { nomProfilPatient: { $autocomplete: recherche } },
            { prenomProfilPatient: { $autocomplete: recherche } },
          ],
        },
      ],
    },
    {
      updated_at: -1,
    },
    options,
  )

  const conversationsTrouvees: ConversationTrouvee[] = channels
    .filter((channel) => channel.data !== undefined)
    //filtre par participant
    .filter((channel) =>
      filtreParticipantsMessagerie?.length > 0
        ? channel.state?.members &&
          Object.values(channel.state?.members).some((member) => filtreParticipantsMessagerie.includes(member.user_id!))
        : true,
    )
    .filter((channel) => channel.id !== undefined)
    .map((channel) => ({ id: channel.id as string }))

  const messages = await client?.search(
    {
      $and: [
        { type: "messaging" },
        { team: identifiantDuCabinet },
        ...(messagesATraiterUniquement ? [{ demandeAideMedecin: true }] : []),
      ],
    },
    {
      text: { $autocomplete: recherche },
      type: "regular",
    },
    {
      limit: 30,
      sort: {
        updated_at: -1,
      },
    },
  )
  const messageChannelIds = messages.results
    .filter((message) => message.message.channel !== undefined)
    //filtre par participant
    .filter((message) =>
      filtreParticipantsMessagerie?.length > 0
        ? message.message.members &&
          Object.values(message.message.members).some((member) =>
            filtreParticipantsMessagerie.includes(member.user_id!),
          )
        : true,
    )
    .map((message) => ({
      id: message.message.channel?.id as string,
      messageTrouve: message.message.text,
    }))

  conversationsTrouvees.push(...messageChannelIds)
  return conversationsTrouvees
}
