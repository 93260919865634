import { SVGProps } from "react"

export const Send = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 26 26" {...props}>
    <rect width={26} height={26} fill="currentColor" rx={4} />
    <g stroke="#3A1C33" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.3}>
      <path
        d="m10.566 13.112 1.329 5.976c.165.745 1.155.906 1.547.252l6.187-10.312a.843.843 0 0 0-.723-1.278H7.242c-.752 0-1.128.909-.597 1.44l3.921 3.922Z"
        clipRule="evenodd"
      />
      <path d="m19.63 8.17-9.067 4.942" />
    </g>
  </svg>
)
