import { aideMettreEnForme } from "@data/preconsultation/mutations/aideMettreEnForme"
import { envoyerPreconsultationDansDoctolib } from "@data/preconsultation/mutations/envoyerPreconsultationDansDoctolib"
import { validerPreconsultationMutation } from "@data/preconsultation/mutations/validerPreconsultation"
import { rendezVousParCabinetQuery } from "@data/rendezVous/queries/rendezVousParCabinetQuery"
import {
  PatientAPI,
  QuestionnaireAPI,
  rendezVousParIdentifiantQuery,
  SyntheseAPI,
} from "@data/rendezVous/queries/rendezVousParIdentifiantQuery"
import { useGQLMutation } from "@data/useGraphQL"
import { di } from "@di"
import { IconButton } from "@ds/button/IconButton"
import { SubmitButton } from "@ds/button/SubmitButton"
import { classNames } from "@ds/classNames"
import { Copy } from "@ds/icons/Copy"
import { Eye } from "@ds/icons/Eye"
import { Loader } from "@ds/icons/Loader"
import { appRoute, preconsultationQuestionnaireDrawerRoute, preconsultationRoute } from "@infra/navigation/router"
import { toast, toastify } from "@infra/toast/toast"
import { useNavigate } from "@tanstack/react-router"
import { format, formatRelative } from "date-fns"
import { fr } from "date-fns/locale"
import { useFlags } from "launchdarkly-react-client-sdk"
import { marked } from "marked"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { BoutonMettreEnForme } from "./BoutonMettreEnForme"

type ToolbarSyntheseProps = {
  markdown: string
  patient: Pick<PatientAPI, "sexe">
  synthese: Pick<SyntheseAPI, "dateDeMiseAJour" | "miseAJourPar"> | null | undefined
  questionnaire: QuestionnaireAPI | null | undefined
  validee: boolean
  className?: string
  dateHeureDeDebut: Date
  consultationTerminee?: boolean
  setMarkdown: (markdown: string) => unknown
  setMiseEnFormeEnCours: (miseEnFormeEnCours: boolean) => unknown
}

export const ToolbarSynthese = ({
  markdown,
  synthese,
  patient,
  questionnaire,
  className,
  validee,
  dateHeureDeDebut,
  consultationTerminee,
  setMarkdown,
  setMiseEnFormeEnCours,
}: ToolbarSyntheseProps) => {
  const { enregistrementPreconsultationDoctolibSynchrone } = useFlags()

  const { t } = useTranslation()
  const navigate = useNavigate()
  const { id: identifiant } = preconsultationRoute.useParams()
  const { mutate: validerPreconsultation, isPending: validerEnCours } = useGQLMutation(validerPreconsultationMutation, {
    invalidateQueryKeys: [[rendezVousParIdentifiantQuery, { identifiant }], [rendezVousParCabinetQuery]],
  })
  const { mutate: envoyerLaPreconsultationSurDoctolib, isPending: envoyerEnCours } = useGQLMutation(
    envoyerPreconsultationDansDoctolib,
    {
      invalidateQueryKeys: [[rendezVousParIdentifiantQuery, { identifiant }], [rendezVousParCabinetQuery]],
    },
  )
  const { mutate: mettreEnForme, isPending: miseEnFormeEnCours } = useGQLMutation(aideMettreEnForme, {
    onSuccess: (res) => {
      di.analytics.trackEvent("Copilot Preconsultation", {
        type: "miseEnForme",
        inputLength: markdown.length,
        outputLength: JSON.stringify(res?.aideALaPreanamnese).length,
      })
      const contenu = res?.aideALaPreanamnese?.miseEnForme?.contenu
      if (contenu == null) return
      setMarkdown(contenu)
    },
  })
  useEffect(() => {
    setMiseEnFormeEnCours(miseEnFormeEnCours)
  }, [miseEnFormeEnCours, setMiseEnFormeEnCours])

  const warningToastContent = (
    <>
      <div>{t("erreurEnvoiDoctolib")}</div>
      <br />
      <div
        className="underline"
        onClick={() => {
          envoyerSurDocto()
          toastify.dismiss("warningDoctolib")
        }}
      >
        {t("ressayer")}
      </div>
    </>
  )

  const envoyerSurDocto = () => {
    envoyerLaPreconsultationSurDoctolib(
      {
        identifiantDuRendezVous: identifiant,
      },
      {
        onSuccess: ({ errors }) => {
          if (errors) {
            toast.warning(warningToastContent, { toastId: "warningDoctolib" })
            return
          }
          navigate({
            to: appRoute.to,
            search: { date: format(dateHeureDeDebut, "yyyy-MM-dd") },
          })
          toast.success(t("contenuEnvoyeAvecSucces"))
        },
      },
    )
  }

  return (
    <div
      className={classNames(
        "flex items-center justify-end gap-1 bg-gradient-to-t from-white via-white pb-7 pt-14",
        className,
      )}
    >
      <BoutonMettreEnForme
        contenu={markdown}
        setContenu={setMarkdown}
        mettreEnForme={() =>
          new Promise((resolve, reject) =>
            mettreEnForme(
              { contenu: markdown },
              {
                onSuccess: (data) => resolve(data?.data?.aideALaPreanamnese.miseEnForme.contenu ?? markdown),
                onError: reject,
              },
            ),
          )
        }
        miseEnFormeEnCours={miseEnFormeEnCours}
      />
      {questionnaire && (
        <IconButton
          data-testid="eye-button"
          onClick={() => {
            navigate({ to: preconsultationQuestionnaireDrawerRoute.to })
          }}
        >
          <Eye className="text-h4" />
        </IconButton>
      )}
      <IconButton
        data-testid="copy-button"
        className="mr-1"
        onClick={async () => {
          const adaptedMarkdown = markdown.replace(/#+ (.*)\n/g, "**$1**\n")
          const html = (await marked(adaptedMarkdown)).replace(/\n/g, "")
          await navigator.clipboard.write([
            new ClipboardItem({
              "text/plain": new Blob([html], { type: "text/plain" }),
              "text/html": new Blob([html], { type: "text/html" }),
            }),
          ])
          toast.success(t("copieDansLePressePapier"))
        }}
      >
        <Copy />
      </IconButton>
      {synthese?.dateDeMiseAJour && (
        <span className="text-p-tiny">
          {synthese.miseAJourPar ?? t("the_patient", { context: patient.sexe })}
          {" - "}
          {formatRelative(synthese.dateDeMiseAJour, new Date(), {
            locale: fr,
          })}
        </span>
      )}
      <div className="flex-1" />
      {/* //TODO: supprimer ce bouton après activation du feature flag*/}
      {!enregistrementPreconsultationDoctolibSynchrone && !validee && (
        <SubmitButton
          title={!validerEnCours ? t("terminerLaPreparation") : undefined}
          onClick={() => {
            validerPreconsultation(
              {
                identifiantDuRendezVous: identifiant,
              },
              {
                onSuccess: () => {
                  navigate({
                    to: appRoute.to,
                    search: { date: format(dateHeureDeDebut, "yyyy-MM-dd") },
                  })
                },
              },
            )
            toast.success(t("contenuEnvoyeAvecSucces"))
          }}
          type="filled"
          icon={validerEnCours && <Loader />}
        />
      )}
      {enregistrementPreconsultationDoctolibSynchrone && (
        <SubmitButton
          title={
            !(validerEnCours || envoyerEnCours)
              ? !validee
                ? t("terminerLaPreparation")
                : t("renvoyerSurDoctolib")
              : undefined
          }
          onClick={() => {
            validerPreconsultation({
              identifiantDuRendezVous: identifiant,
            })
            envoyerSurDocto()
          }}
          type={!validee ? "filled" : "outlined"}
          icon={(validerEnCours || envoyerEnCours) && <Loader />}
          disabled={consultationTerminee || validerEnCours || envoyerEnCours}
        />
      )}
    </div>
  )
}
