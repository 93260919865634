import { SVGProps } from "react"

export const Bin = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 14 14" fill="none" {...props}>
    <g>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M1.83 3.325h10.32M5.885 9.592V5.906m2.212 3.686V5.906m0-4.055H5.884a.737.737 0 0 0-.738.737v.737h3.687v-.737a.737.737 0 0 0-.737-.737Zm2.268 9.645a.737.737 0 0 1-.735.677H4.351a.737.737 0 0 1-.735-.677l-.681-8.17h8.11l-.681 8.17Z"
      />
    </g>
  </svg>
)
