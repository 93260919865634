import { SVGProps } from "react"

export const PulseOximeter = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
    <g fill="currentColor">
      <path
        fillRule="evenodd"
        d="M2.5 5.833v.656l.095.125c.222.29.342.646.342 1.012v.582c0 .365-.12.72-.342 1.011l-.095.125V10h4.854c1.27 0 2.23-.97 2.23-2.083 0-1.113-.96-2.084-2.23-2.084H2.5ZM2.323 5c-.363 0-.656.28-.656.625v.864c0 .183.06.36.17.506l.096.125c.11.145.171.323.171.506v.582c0 .183-.06.36-.171.506l-.095.125a.833.833 0 0 0-.171.505v.864c0 .345.293.625.656.625h5.031c1.691 0 3.062-1.306 3.062-2.916C10.416 6.306 9.046 5 7.354 5H2.323Z"
        clipRule="evenodd"
      />
      <path d="M9.167 7.917a.833.833 0 1 1-1.667 0 .833.833 0 0 1 1.667 0Z" />
      <path
        fillRule="evenodd"
        d="M6.25 7.083H4.167V8.75H6.25V7.083ZM3.333 6.25v3.333h3.75V6.25h-3.75ZM9.584 8.766v.833h1.691a1.593 1.593 0 0 0 .545 2.107 1.595 1.595 0 0 0 1.234 2.605h.281a1.595 1.595 0 0 0 1.402 2.356h3.59v-.834h-3.59a.761.761 0 1 1 0-1.522h1.685v-.834h-3.368a.761.761 0 1 1 0-1.522h2.23v-.834h-2.607a.761.761 0 1 1 0-1.522h2.607v-.833h-5.7Zm.208-1.523h6.588c.91 0 1.161-1.25.322-1.602l-1.982-.829a.678.678 0 0 1 .503-1.26l3.11 1.135v-.843l-2.814-1.07A1.511 1.511 0 0 0 14.4 5.58l1.981.829H9.792v.833Z"
        clipRule="evenodd"
      />
    </g>
  </svg>
)
