import { SVGProps } from "react"

export const Attachment = (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 18 18" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.10222 3.10256C9.42646 1.77832 11.5735 1.77831 12.8977 3.10255C14.222 4.42679 14.222 6.57381 12.8977 7.89805L8.89772 11.8981C8.12576 12.67 6.87418 12.67 6.10222 11.8981C5.33027 11.1261 5.33026 9.87451 6.10222 9.10256L10.1022 5.10256C10.3219 4.88289 10.678 4.88289 10.8977 5.10256C11.1174 5.32223 11.1174 5.67838 10.8977 5.89805L6.89771 9.89805C6.5651 10.2307 6.5651 10.7699 6.89771 11.1026C7.23033 11.4352 7.76961 11.4352 8.10222 11.1026L12.1022 7.10256C12.9871 6.21766 12.9871 4.78295 12.1022 3.89805C11.2173 3.01315 9.78262 3.01315 8.89772 3.89805L4.89771 7.89805C3.46053 9.33524 3.46053 11.6654 4.89771 13.1026C6.3349 14.5397 8.66504 14.5397 10.1022 13.1026L14.1022 9.10256C14.3219 8.88289 14.678 8.88289 14.8977 9.10256C15.1174 9.32223 15.1174 9.67838 14.8977 9.89805L10.8977 13.8981C9.02119 15.7746 5.97874 15.7746 4.10222 13.8981C2.22569 12.0215 2.22569 8.97908 4.10222 7.10256L8.10222 3.10256Z"
      fill="#28303F"
    />
  </svg>
)
