import { graphql } from "@data/gql"

export const categoriesDeDocumentsQuery = graphql(`
  query categoriesDeDocuments {
    categoriesDeDocument {
      id
      nom
    }
  }
`)
