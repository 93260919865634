import { categoriesDeDocumentsQuery } from "@data/messagerie/categoriesDeDocumentQuery"
import { useGraphQLQuery } from "@data/useGraphQL"
import { classNames } from "@ds/classNames"
import { ChevronDown } from "@ds/icons/ChevronDown"
import { Select } from "@headlessui/react"
import { useTranslation } from "react-i18next"

interface SelectCategorieDeDocumentProps {
  value: string
  onChange: (value: string) => unknown
  className?: string
  disabled?: boolean
}

export const SelectCategorieDeDocument = ({ value, onChange, className, disabled }: SelectCategorieDeDocumentProps) => {
  const { t } = useTranslation()
  const { data } = useGraphQLQuery({ document: categoriesDeDocumentsQuery })
  return (
    <div className="relative">
      <Select
        disabled={disabled}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className={classNames(
          "w-full appearance-none rounded-xl bg-extra-light-grey px-6 py-4 text-p-small text-dark-grey",
          className,
        )}
      >
        <>
          {value === "" && (
            <option key="null" value="">
              {t("messagerie.selectionnerUneCategorie")}
            </option>
          )}
          {data?.data?.categoriesDeDocument.map((categorieDeDocument) => (
            <option key={categorieDeDocument.id} value={categorieDeDocument.id}>
              {categorieDeDocument.nom}
            </option>
          ))}
        </>
      </Select>
      <ChevronDown className="absolute right-4 top-1/2 -translate-y-1/2 text-p-medium" />
    </div>
  )
}
