export interface Soignant {
  id: string
  civilite?: string
  prenom?: string
  nom?: string
  urlAvatar?: string
  metier?: string
}

export const buildNomComplet = ({ civilite, prenom, nom, metier }: Soignant) => {
  if (metier === "medecin") {
    return [civilite, prenom, nom].filter((i) => !!i).join(" ")
  }
  return [prenom, nom].filter((i) => !!i).join(" ")
}
