import { Channel, useChatContext } from "stream-chat-react"
import { CustomTypingIndicator } from "@features/messagerie/CustomTypingIndicator.tsx"
import { CustomSystemMessage } from "@features/messagerie/CustomSystemMessage.tsx"
import { ChannelInner } from "@features/messagerie/ChannelInner.tsx"
import { CustomDateSeparator } from "./CustomDateSeparator"
import { useArchivage } from "./hooks/useArchivage"
import { conversationRoute } from "@infra/navigation/router"

export const Conversation = () => {
  const { client } = useChatContext()
  const { conversationId } = conversationRoute.useParams()

  const channel = client.getChannelById("messaging", conversationId, {})
  const { Modale, setAfficherConfirmation } = useArchivage(channel)

  return (
    <Channel
      channel={channel}
      UnreadMessagesSeparator={() => {
        return <div className="w-full border-[1px] border-t-extra-light-grey" />
      }}
      TypingIndicator={CustomTypingIndicator}
      UnreadMessagesNotification={() => <div />}
      MessageSystem={CustomSystemMessage}
      EmptyStateIndicator={() => <></>}
      DateSeparator={CustomDateSeparator}
      activeUnreadHandler={() => {}} // do not update document.title here
    >
      <ChannelInner channel={channel} setAfficherConfirmation={setAfficherConfirmation} />
      <Modale />
    </Channel>
  )
}
