import { SVGProps } from "react"

export const Warning = (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.99859 1.27832V1.27832C10.4353 1.27832 13.2208 4.0638 13.2208 7.50054V7.50054C13.2208 10.9373 10.4353 13.7228 6.99859 13.7228V13.7228C3.56185 13.7228 0.776367 10.9373 0.776367 7.50054V7.50054C0.776367 4.0638 3.56185 1.27832 6.99859 1.27832Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M6.999 7.84644L6.999 4.38965" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M6.99831 10.2656C6.9029 10.2656 6.82547 10.343 6.82616 10.4385C6.82616 10.5339 6.90359 10.6113 6.999 10.6113C7.09441 10.6113 7.17184 10.5339 7.17184 10.4385C7.17184 10.343 7.09441 10.2656 6.99831 10.2656"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
