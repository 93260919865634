import { SVGProps } from "react"

export const Filter = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 14 14" {...props}>
    <path
      fill="currentColor"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M10.978 1.166H3.022c-1.014 0-1.62 1.117-1.057 1.952l3.338 4.364c.278.412.427.897.427 1.393v3.329c0 .559.684.839 1.084.444l1.27-1.256a.624.624 0 0 0 .186-.444V8.875c0-.496.149-.98.427-1.393l3.338-4.364c.562-.835-.043-1.952-1.057-1.952Z"
    />
  </svg>
)
